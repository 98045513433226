import { urls, TYPE } from 'Reducers/faq';

export const getFaq = (categoryId, type, filters = {}) => (dispatch, getState, { post }) => {
    const state = getState();

    const { categories } = state;
    const category = categories?.elements[categoryId];
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;

    const location = state.locations?.selectedLocation?.name;
    const actionType = `ELEMENT_${TYPE}`;

    const url = urls.getFaq(parentCategoryId);

    return dispatch(post(url, actionType, {
        filters,
        location,
        type
    }));
};
