import { MAIN_CATEGORY } from 'Constants/categories';
import { FAQS } from 'Constants/faq';

const TOP_CONTENT = 3;
const L3_LOCATION = 'CITY';
const PROPERTY_FOR_RENT = '1723';
const PROPERTY_FOR_SALE = '1725';

function getTopContent(input) {
    const data = [...input];
    const last = data.pop();
    const result = data.length === 0 ? last : `${data.join(', ') } and ${ last}`;

    return result || '';
}

function getFormattedNumber(intl, count) {
    return intl.formatNumber(count);
}

function getCategoryCount(categoryFilters, intl, { id, key }) {
    return getFormattedNumber(intl, categoryFilters?.sub_categories?.find(b => {
        if (key) {
            return b?.key === key;
        }

        if (id) {
            return b?.id === id;
        }

        return false;
    })?.count || 0);
}

function getTotalCount(categoryFilters, intl) {
    return getFormattedNumber(intl, categoryFilters?.sub_categories?.reduce((acc, curr) => (acc += curr?.count || 0), 0) || 0);
}

function getSelectedFilter(selectedFilters, key) {
    return selectedFilters?.find(f => f.filterId === key);
}

export function getContentForBikesCategory(
    categoryId,
    parentId,
    {
        categoryFilters,
        intl,
        selectedFilters,
        selectedLocation,
        topModels
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.BIKES];
    const make = getSelectedFilter(selectedFilters, 'make');
    const model = getSelectedFilter(selectedFilters, 'model');

    const content = {
        number_of_bicycles: getCategoryCount(categoryFilters, intl, { key: 'bicycles' }),
        number_of_bikes: getTotalCount(categoryFilters, intl),
        number_of_scooters: getCategoryCount(categoryFilters, intl, { key: 'scooters' }),
        make: make?.label || '',
        model: model?.label || '',
        top_models: getTopContent((topModels || []).reduce((acc, curr) => acc.push(curr.name) && acc, []))
    };

    const isMakeFilterApplied = Boolean(make?.id);
    const isModelFilterApplied = Boolean(model?.id);

    let type;

    if (selectedLocation?.type === L3_LOCATION) {
        if (categoryId === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
        else if (isModelFilterApplied) {
            type = TYPE.MODEL_CATEGORY_L2_LOCATION_L3;
        }
        else if (isMakeFilterApplied) {
            type = TYPE.BRAND_CATEGORY_L2_LOCATION_L3;
        }
        else {
            type = TYPE.CATEGORY_L2_LOCATION_L3;
        }
    }
    else {
        type = TYPE.GENERAL;
    }

    return { content, type };
}

export function getContentForElectronicsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        minPrice,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.ELECTRONICS];
    const topTypes = getTopContent(categoryFilters?.sub_categories
        ?.sort((a, b) => b.count - a.count)
        .map(i => i.name)
        .slice(0, TOP_CONTENT) || []);
    const totalNumber = category.id === parentId
        ? getTotalCount(categoryFilters, intl)
        : getCategoryCount(categoryFilters, intl, { id: category.id });

    const content = {
        number_of_items: totalNumber,
        top_models: topTypes,
        type: category.name,
        starting_price: intl.formatNumber(minPrice || 0)
    };

    const categoryContent = category.id === parentId
        ? TYPE.CATEGORY_L1_LOCATION_L3
        : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content,
        type: selectedLocation.type === L3_LOCATION ? categoryContent : ''
    };
}

function getPopularLocations(locations) {
    if (locations && locations?.length > 0 && locations[0].selected) {
        return locations[0]?.list?.sort((a, b) => b.count - a.count).map(i => i.title || '').slice(0, TOP_CONTENT);
    }
    else if (locations?.[0]?.list) {
        return getPopularLocations(locations?.[0]?.list);
    }

    return [];
}

export function getContentForRealEstateCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        locations,
        minPrice,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.REAL_ESTATE];
    const popular_areas = getTopContent(getPopularLocations(locations) || []);
    let totalNumber = getTotalCount(categoryFilters, intl);

    if (category?.id === PROPERTY_FOR_RENT) {
        totalNumber = getCategoryCount(categoryFilters, intl, { id: category.id });
    }
    else if (category?.id === PROPERTY_FOR_SALE) {
        totalNumber = getCategoryCount(categoryFilters, intl, { id: category.id });
    }

    let type;

    if (selectedLocation?.type === L3_LOCATION) {
        if (category.id === PROPERTY_FOR_RENT) {
            type = TYPE.RENT_CATEGORY_L2_LOCATION_L3;
        }
        else if (category.id === PROPERTY_FOR_SALE) {
            type = TYPE.SALE_CATEGORY_L2_LOCATION_L3;
        }
        else if (category.id === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
    }

    return {
        content: {
            number: totalNumber,
            popular_areas,
            starting_price: intl.formatNumber(minPrice || 0)
        },
        type
    };
}

export function getContentForJobsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.JOBS];
    const topTypes = categoryFilters?.sub_categories?.sort((a, b) => b.count - a.count).map(i => i.name).slice(0, TOP_CONTENT);
    const totalNumber = category.id === parentId
        ? getTotalCount(categoryFilters, intl)
        : getCategoryCount(categoryFilters, intl, { id: category.id });
    const categoryContent = category?.id === parentId ? TYPE.CATEGORY_L1_LOCATION_L3 : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content: {
            category: category?.name,
            job_types: getTopContent(topTypes || []),
            popular_jobs: getTopContent(topTypes || []),
            number: totalNumber
        },
        type: selectedLocation?.type === L3_LOCATION ? categoryContent : TYPE.GENERAL
    };
}

export function getContentForMobilesCategory(
    category,
    parentId,
    {
        categoryFilters,
        selectedFilters,
        intl,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.MOBILES];
    let totalNumber = getTotalCount(categoryFilters, intl);

    if (category?.id !== parentId) {
        totalNumber = getCategoryCount(categoryFilters, intl, { id: category.id });
    }

    const make = getSelectedFilter(selectedFilters, 'make');
    const isMakeFilterApplied = Boolean(make?.id);

    let type;

    if (selectedLocation.type === L3_LOCATION) {
        if (category.id === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
        else if (isMakeFilterApplied) {
            type = TYPE.BRAND_CATEGORY_L2_LOCATION_L3;
        }
        else {
            type = TYPE.CATEGORY_L2_LOCATION_L3;
        }
    }
    else {
        type = TYPE.GENERAL;
    }

    return {
        content: {
            make: make?.label || '',
            number: totalNumber
        },
        type
    };
}

export function getContentForCarsCategory(
    {
        selectedFilters,
        params: { geoID }
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.CARS];
    const make = getSelectedFilter(selectedFilters, 'make');
    const content = {
        make: make?.label || ''
    };

    const isMakeFilterApplied = Boolean(make?.id);
    let type;

    if (geoID && !isMakeFilterApplied) {
        type = TYPE.WITHOUT_BRAND_WITH_LOCATION;
    }
    else if (!geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITHOUT_LOCATION;
    }
    else if (geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITH_LOCATION;
    }
    else {
        type = TYPE.GENERAL;
    }

    return { content, type };
}

function getType(id) {
    let type = null;

    for (const [k, v] of Object.entries(MAIN_CATEGORY)) {
        if (id === v) {
            type = k;
            break;
        }
    }

    return type;
}

export function getFAQTypes(catId, id, { params: { geoID }, selectedFilters, selectedLocation }) {
    const cat = getType(id);
    const TYPE = FAQS[MAIN_CATEGORY[cat]];
    const isMakeFilterApplied = Boolean(getSelectedFilter(selectedFilters, 'make')?.id);
    const isModelFilterApplied = Boolean(getSelectedFilter(selectedFilters, 'model')?.id);

    if (TYPE) {
        if (id === MAIN_CATEGORY.BIKES
            || id === MAIN_CATEGORY.ELECTRONICS
            || id === MAIN_CATEGORY.JOBS
            || id === MAIN_CATEGORY.MOBILES
        ) {
            if (selectedLocation.type === L3_LOCATION) {
                if (isModelFilterApplied) {
                    return TYPE?.MODEL_CATEGORY_L2_LOCATION_L3;
                }
                else if (isMakeFilterApplied) {
                    return TYPE?.BRAND_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === id) {
                    return TYPE?.CATEGORY_L1_LOCATION_L3;
                }

                return TYPE?.CATEGORY_L2_LOCATION_L3;
            }

            return TYPE?.GENERAL;
        }
        else if (id === MAIN_CATEGORY.REAL_ESTATE) {
            if (selectedLocation?.type === L3_LOCATION) {
                if (catId === PROPERTY_FOR_RENT) {
                    return TYPE.RENT_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === PROPERTY_FOR_SALE) {
                    return TYPE.SALE_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === id) {
                    return TYPE.CATEGORY_L1_LOCATION_L3;
                }
            }
        }
        else if (id === MAIN_CATEGORY.CARS) {
            if (geoID && !isMakeFilterApplied) {
                return TYPE.WITHOUT_BRAND_WITH_LOCATION;
            }
            else if (!geoID && isMakeFilterApplied) {
                return TYPE.BRAND_WITHOUT_LOCATION;
            }
            else if (geoID && isMakeFilterApplied) {
                return TYPE.BRAND_WITH_LOCATION;
            }

            return TYPE.GENERAL;
        }

        return TYPE.GENERAL;
    }

    return undefined;
}

function interpolateText(text = '', types = {}) {
    if (types && Object.entries(types).length > 0) {
        for (const [key, value] of Object.entries(types)) {
            if (text) {
                text = text.replaceAll(`{${key}}`, value);
            }
        }
    }

    return text;
}

function interpolateLocation(text = '', location = '') {
    if (location && text) {
        text = text.replaceAll('{location}', location);
    }

    return text ?? '';
}

export function interpolate(text, location, content) {
    const textWithLocation = interpolateLocation(text, location);

    return interpolateText(textWithLocation, content);
}

export function getTransformedMakeModels(filter) {
    let topModels = [];

    const values = filter?.render?.customConfiguration?.values || [];

    if (filter.render && values?.length > 0) {
        const {
            render: {
                customConfiguration: { valuesInfo } = {}
            } = {}
        } = filter;

        topModels = Object.entries(valuesInfo || {})
            // eslint-disable-next-line no-unused-vars
            .filter(([_k, v]) => v?.abundance?.count)
            // eslint-disable-next-line no-unused-vars
            .sort(([_k1, v1], [_k2, v2]) => v2.abundance.count - v1.abundance.count)
            // eslint-disable-next-line no-unused-vars
            .map(([_k, v]) => ({ name: v.name }))
            .slice(0, TOP_CONTENT);
    }

    return topModels;
}

export function getMinimumPrice({ items: { elements }}) {
    let min = 0;

    // eslint-disable-next-line no-unused-vars
    for (const [_k, v] of Object.entries(elements || {})) {
        const minVal = min > v?.price?.value?.raw ? v.price.value.raw : min;

        // eslint-disable-next-line no-unused-expressions
        min = min === 0 ? (v?.price?.value?.raw || 0) : minVal;
    }

    return min;
}

export function getMultipleFilterSelected(selectedFilters) {
    return selectedFilters.filter(i => i.filterId === 'make')?.length > 1
    || selectedFilters.filter(i => i.filterId === 'model')?.length > 1
    || selectedFilters.filter(i => i.filterId !== 'make' && i.filterId !== 'model')?.length > 1;
}
